<template lang="pug">
    .language-selector.mt-0.pt-0
        v-menu(offset-y)
            template(v-slot:activator="{ on, attrs }")
                v-btn(depressed small color="grey lighten-5" v-bind="attrs" v-on="on") {{prettyLang}}
            v-list
                v-list-item(v-for="item in items", :key="item.value" @click="setLang(item.value)")
                    v-list-item-title {{item.text}}

</template>

<script>
import { get, call } from 'vuex-pathify'
export default {
  name: 'NavLanguageSelector',
  data () {
    return {
      items: [{ text: 'English', value: 'en' }, { text: 'русский‎', value: 'ru' }, { text: '한국어', value: 'ko' }]
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    prettyLang() {
      if (this.lang === 'en') return '[En] ру 한'
      else if (this.lang === 'ko') return 'En ру [한]'
      else if (this.lang === 'ru') return 'En [ру] 한'
      else return this.lang
    }
  },
  methods: {
    ...call({ setLang: 'user/setLang' })
  }
}
</script>
