<template lang="pug">
    .subfooter.grey.lighten-3
        v-container.text-center
            small PROIT English | <a class="text-none text-dark" href="mailto:support@ProitEnglish.com">support@ProitEnglish.com</a> | Unit 346, 3/F, Peninsula Centre, No. 67 Mody Road, Tsim Sha Tsui, Kowloon, Hong Kong
</template>

<script>
export default {
  name: 'SubFooter'
}
</script>

<style lang="scss" scoped>

</style>
