<template lang="pug">
    .footer.grey.lighten-5.text-center
        v-container.py-12
            v-row(align="center")
                v-col(cols="12", md="6")
                    h2.mb-0 {{text.title}}
                v-col(cols="12", md="6")
                    p.mb-0(v-for="p in text.paragraphs", v-html="p")
</template>

<script>
import { get } from 'vuex-pathify'
export default {
  name: 'Footer',
  data() {
    return {
      textVersions: {
        en: {
          title: 'Have a question? Get in touch',
          paragraphs:
            ['You can email us at <a class="text-none" href="mailto:support@ProItEnglish.com">support@ProItEnglish.com</a>', 'Or call <a class="text-none" href="tel:+18662338741">+1 866 233 8741</a>']
        },
        ru: {
          title: 'Есть вопрос? Свяжитесь с нами.',
          paragraphs:
            ['Вы можете написать нам по адресу <a class="text-none" href="mailto:support@ProItEnglish.com">support@ProItEnglish.com</a>', 'Звоните: <a class="text-none" href="tel:+18662338741">+1 866 233 8741</a>']
        },
        ko: {
          title: '질문 있으신가요? 연락하세요.',
          paragraphs:
            ['<a class="text-none" href="mailto:support@ProItEnglish.com">support@ProItEnglish.com</a> 이메일을 보내주십시오.', '<a class="text-none" href="tel:+18662338741">+1 866 233 8741</a>']
        }
        // ko: { title: '질문 있으신가요? 연락하세요.', paragraphs: '<a class="text-none" href="mailto:support@ProItEnglish.com">support@ProItEnglish.com</a> 이메일을 보내주십시오.' }
      }
    }
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
