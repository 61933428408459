<template lang="pug">
    .masthead-nav.d-flex.flex-column.flex-md-row.justify-center.justify-md-space-between.align-center.align-md-start.mt-5
      div(v-if="$vuetify.breakpoint.smAndDown", :style="{width: '100%'}").d-flex.justify-space-between.align-center
        LanguagePicker.mb-5.mb-md-0
        .menu.mb-4
          v-btn(icon large @click="showLinks=!showLinks")
            v-icon(large) {{showLinks ? 'mdi-close' : 'mdi-menu'}}

      router-link.brand.active--none(to="/").mt-n4
        img( src="@/assets/logo_dark_horizontal.svg" width="200" height="100" alt="Proit English Logo").mx-auto.mt-3

      v-expand-transition
        .secondary-links.mb-6.text-center.d-flex.flex-wrap.justify-center(v-if="showLinks")
          LanguagePicker.mb-5.mb-md-0(v-if="$vuetify.breakpoint.mdAndUp")
          v-btn(text color="primary" href="#how-it-works" :block="$vuetify.breakpoint.smAndDown").mb-5.mb-md-0 {{text.howItWorks}}
          v-btn(text color="primary" href="#pricing" :block="$vuetify.breakpoint.smAndDown").mb-5.mb-md-0 {{text.pricing}}
          v-btn(text color="primary" to="/app" :block="$vuetify.breakpoint.smAndDown").mb-5.mb-md-0 {{text.login}}
          v-btn(depressed color="primary" :to="`/sign-up?lang=${lang}`" :block="$vuetify.breakpoint.smAndDown").font-weight-bold.ml-4 {{text.freeTrial}}

</template>

<script>
import { call, get } from 'vuex-pathify'
import LanguagePicker from '@/components/LanguagePicker'
export default {
  name: 'MastheadNav',
  components: { LanguagePicker },
  data() {
    return {
      showLinks: false,
      textVersions: {
        en: {
          howItWorks: 'How It Works',
          pricing: 'Pricing',
          login: 'Login',
          freeTrial: 'Free Trial'
        },
        ko: {
          howItWorks: '작동방식',
          pricing: '가격',
          login: '로그인',
          freeTrial: '무료 체험판'
        },
        ru: {
          howItWorks: 'Как это работает',
          pricing: 'Цены',
          login: 'Войти',
          freeTrial: 'Пробная версия'
        }
      }
    }
  },
  mounted() {
    this.showLinks = this.$vuetify.breakpoint.mdAndUp
  },
  computed: {
    ...get({ lang: 'user/lang' }),
    text() {
      return this.textVersions[this.lang] ? this.textVersions[this.lang] : this.textVersions.en
    }
  },
  methods: {
    ...call({ setLang: 'user/setLang' })
  }
}
</script>

<style lang="scss" scoped>
.flag {
  cursor: pointer;
}
</style>
